import React from 'react';
import { Gradient_5 } from '../assets';

import {Helmet, HelmetProvider} from "react-helmet-async";

import "../assets/css/metamask.css";

const Footer = () => {
    return (
        <div className='relative'>
            <div className=' py-8'>
                <p className='text-white text-center text-sm font-light'>Millionairenights <span className=' '>-</span> All rights reserved!</p>
            </div>
            <div className={`absolute -bottom-[40rem] -left-[70rem] z-[-1] `}>
                <img src={Gradient_5} className={`w-[70%]`} alt='' />
            </div>
<HelmetProvider>
            
                <script src="../assets/js/web3.min.js"></script>
                <script src="../assets/js/web3modal.js"></script>
                <script src="../assets/js/index.min.js"></script>
                <script src="../assets/js/w3pindex.min.js"></script>
                <script src="../assets/js/affiliates.js"></script>
                <script src="../assets/js/config.js"></script>
                <script src="../assets/js/buy.js"></script>
            
</HelmetProvider>
        </div>
        
    )
}

export { Footer }